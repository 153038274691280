// import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs';
//
// @Injectable({
//   providedIn: 'root'
// })
// export class ModalService {
//   // Gestion de l'état de visibilité de la modale
//   private visibleSource = new BehaviorSubject<boolean>(false);
//   public visible$ = this.visibleSource.asObservable();
//
//   constructor() {}
//
//   // Ouvrir la modale
//   open(): void {
//     this.visibleSource.next(true);
//   }
//
//   // Fermer la modale
//   close(): void {
//     this.visibleSource.next(false);
//   }
// }

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modalVisibilitySubject = new BehaviorSubject<{ modalId: string, visible: boolean }>({ modalId: '', visible: false });
  modalVisibility$ = this.modalVisibilitySubject.asObservable();

  openModal(modalId: string) {
    console.log(modalId);
    this.modalVisibilitySubject.next({ modalId, visible: true });
  }

  closeModal(modalId: string) {
    this.modalVisibilitySubject.next({ modalId, visible: false });
  }
}
