<div *ngIf="!closed" class="backdrop" (click)="close()"></div>

<!-- Modale avec animation -->
<div *ngIf="!closed" [ngClass]="{ 'modal': true, 'show': isVisible, 'hider': closed }" class="bgDivColor rainbows">
  <div class="">
    <div class="modal-header">
      <ng-container *ngTemplateOutlet="header"></ng-container>
      <button (click)="close()">Fermer</button>
    </div>
    <div class="modal-content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </div>
</div>


