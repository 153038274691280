import {Component, Input, OnInit} from '@angular/core';
import {Article} from '../../../../Models/article.model';
import {DatePipe, NgOptimizedImage} from '@angular/common';
import {HttpService, ResponseDto} from '../../../../Services/http.service';
import {ModalComponent} from '../../../modal/modal.component';
import {ModalService} from '../../../../Services/modal.service';
import { CommonModule} from '@angular/common';
import {ArticleCommentPaginated} from '../../../../Models/article-comment-paginated';
import {Comment} from '@angular/compiler';

@Component({
  selector: 'app-article',
  standalone: true,
  imports: [
    DatePipe,
    NgOptimizedImage,
    ModalComponent,
    CommonModule
  ],
  templateUrl: './article.component.html',
  styleUrl: './article.component.scss'
})
export class ArticleComponent implements OnInit {
  @Input() article!: Article;
  comments!: ArticleCommentPaginated;
  constructor(private _httpService: HttpService, private _modalService: ModalService) {
  }

  async ngOnInit() {
    var res:ResponseDto<ArticleCommentPaginated> = await this._httpService.get('Article/comments', { articleId : this.article.id, page: 1, limit: 10 });
    if(res.data){
      this.comments = res.data;
      console.log(this.comments.comments);
    }
  }

  public async like(): Promise<void> {
    console.log(this.article);
  }

  public async comment(id : number): Promise<void> {
    if(""+id == ""+this.article.id) {
      var res: ResponseDto<ArticleCommentPaginated> = await this._httpService.get('Article/comments', {
        articleId: this.article.id,
        page: 1,
        limit: 10
      });
      if (res.data) {
        this.comments = res.data;
        console.log(this.comments.comments);
      }
      console.log(id);
      this._modalService.openModal(""+id);
    }
  }
}
