<div class="box article">
  <div class="article-title">
    {{ article.title}}
  </div>
  <div class="article-content">
    {{ article.content}}
  </div>

  <hr style="width: 100%"/>
  <div class="article-info">
    <div class="like-div">
      <img ngSrc="assets/img/coeur.gif" width="23" height="23" (click)="like()" class="like" alt="like" /> {{ article.nbLikes }}
    </div>
    <div class="comment-div">
      <img ngSrc="assets/img/comment.png" width="19" height="23" (click)='comment(article.id)' class="comment" alt="comment" /> {{ article.nbComments }}
    </div>
  </div>
  <div class="article-by">
    <div>
      Par: {{ article.user.name }}
    </div>
    <div>
      le: {{ article.dateCreated | date:'dd/MM/yyyy à H:mm' }}
    </div>
  </div>
</div>
<ng-template #header>
  <h2>Commentaires</h2>
</ng-template>
<ng-template #content>
    <div *ngFor="let comment of comments.comments">
      <div class="comment-content">
        {{ comment.content }}
      </div>
      <div class="comment-info">
        <div>
          Par: {{ comment.user.name }}
        </div>
        <div>
          le: {{ comment.dateCreated | date:'dd/MM/yyyy à H:mm' }}
        </div>
      </div><div class="comment-content">
        {{ comment.content }}
      </div>
      <div class="comment-info">
        <div>
          Par: {{ comment.user.name }}
        </div>
        <div>
          le: {{ comment.dateCreated | date:'dd/MM/yyyy à H:mm' }}
        </div>
      </div><div class="comment-content">
        {{ comment.content }}
      </div>
      <div class="comment-info">
        <div>
          Par: {{ comment.user.name }}
        </div>
        <div>
          le: {{ comment.dateCreated | date:'dd/MM/yyyy à H:mm' }}
        </div>
      </div><div class="comment-content">
        {{ comment.content }}
      </div>
      <div class="comment-info">
        <div>
          Par: {{ comment.user.name }}
        </div>
        <div>
          le: {{ comment.dateCreated | date:'dd/MM/yyyy à H:mm' }}
        </div>
      </div><div class="comment-content">
        {{ comment.content }}
      </div>
      <div class="comment-info">
        <div>
          Par: {{ comment.user.name }} le: {{ comment.dateCreated | date:'dd/MM/yyyy à H:mm' }}
        </div>
        <div>
          le: {{ comment.dateCreated | date:'dd/MM/yyyy à H:mm' }}
        </div>
      </div>
      <div class="comment-content">
        {{ comment.content }}
      </div>
      <div class="comment-info">
        <div>
          Par: {{ comment.user.name }}
        </div>
        <div>
          le: {{ comment.dateCreated | date:'dd/MM/yyyy à H:mm' }}
        </div>
      </div>
    </div>
</ng-template>
<div class="modal-container">
  <app-modal [modalId]="''+article.id" [header]="header" [content]="content"></app-modal>
</div>
