// import {Component, ViewEncapsulation, ViewChild, Input, TemplateRef, ContentChild} from '@angular/core';
// import { DialogComponent, ButtonPropsModel, DialogModule } from '@syncfusion/ej2-angular-popups';
// import { EmitType } from '@syncfusion/ej2-base';
// import { CommonModule} from '@angular/common';
//
// import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
// import {ModalService} from '../../Services/modal.service';
// @Component({
//   selector: 'app-modal',
//   standalone: true,  // Déclaration comme composant standalone
//   templateUrl: './modal.component.html',
//   imports: [ButtonModule, DialogModule,CommonModule],  // Importation du module Syncfusion
// })
// export class ModalComponent {
//   // @ContentChild('headerTemplate') headerTemplate?: TemplateRef<any>;
//   // @ContentChild('contentTemplate') contentTemplate!: TemplateRef<any>;
//   // @ContentChild('footerTemplate') footerTemplate?: TemplateRef<any>;
//   //
//   // @ViewChild('defaultDialog')
//   // public defaultDialog!: DialogComponent;
//   // public dialogHeader?: TemplateRef<any> = this.headerTemplate;
//   // public dialogCloseIcon: Boolean = true;
//   // public dialogWidth: string = '285px';
//   // public animationSettings: Object = { effect: 'Zoom' };
//   // public isModal: Boolean = true;
//   // public target: string = '.control-section';
//   // public showCloseIcon: Boolean = false;
//   // public visible: Boolean = true;
//   //
//   // public dlgButtonClick = (): void => {
//   //   this.defaultDialog.hide();
//   // }
//   //
//   // public dialogBtnClick: EmitType<Object> = (args: any) => {
//   //   const effects = args.target.id;
//   //   let txt = args.target.parentElement.innerText;
//   //   txt = (txt === 'Zoom In/Out') ? 'Zoom In or Out' : txt;
//   //   this.defaultDialog.content = this.contentTemplate;
//   //   this.defaultDialog.animationSettings = { effect: effects, duration: 400 };
//   //   this.defaultDialog.show();
//   // }
//   //
//   // public defaultDlgButtons:  ButtonPropsModel[] = [
//   //   { click: this.dlgButtonClick.bind(this), buttonModel: { content: 'Hide', isPrimary: true } }
//   // ];
//   //
//   // constructor(private modalService: ModalService) {
//   //   // Abonnement à l'état du service
//   //   this.modalService.visible$.subscribe((isVisible) => {
//   //     this.visible = isVisible;
//   //     if (this.defaultDialog) {
//   //       isVisible ? this.defaultDialog.show() : this.defaultDialog.hide();
//   //     }
//   //   });
//   // }
//   //
//   // ngAfterContentInit() {
//   //   console.log('Header:', this.headerTemplate);
//   //   console.log('Content:', this.contentTemplate);
//   //   console.log('Footer:', this.footerTemplate);
//   // }
// }

import {Component, ContentChild, TemplateRef, OnInit, Input} from '@angular/core';
import { ModalService} from '../../Services/modal.service';
import {AsyncPipe, CommonModule} from '@angular/common';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  standalone: true,
  imports: [
    AsyncPipe,
    CommonModule,
  ],
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() modalId: string = '';  // ID unique de la modale
  @Input() header!: any;
  @Input() content!: any;
  isVisible: boolean = false;
  isClosing: boolean = false; // Variable pour gérer l'animation de fermeture
  closed: boolean = true;
  constructor(private modalService: ModalService) {
    this.modalService.modalVisibility$.subscribe((state) => {
      console.log(state.modalId+" state.modalId");
      console.log(this.modalId+" this.modalId");
      if (state.modalId === this.modalId) {
        this.isVisible = state.visible;
        if(this.isVisible){
          this.closed = false;
        }
        if (!this.isVisible) {
          setTimeout(() => {
            this.isVisible = false;
            this.closed = true// Masquer la modale après l'animation
          }, 300); // La durée de l'animation de fermeture (300ms)
        }
      }
    });
  }

  close() {
    // Appel du service pour fermer la modale
    this.modalService.closeModal(this.modalId);
  }
}

