import {Component, OnInit} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {LoginComponent} from "../../Auth/login/login.component";
import {Observable} from 'rxjs';
import {LoginService} from '../../../Services/Auth/login.service';

@Component({
  selector: 'app-header',
  standalone: true,
    imports: [
        AsyncPipe,
        LoginComponent,
        NgIf
    ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  isLogged$!: Observable<boolean | null>;
  showLogin = false;
  constructor(private loginService: LoginService) {
    this.isLogged$ = this.loginService.isLogged$;
  }

  async ngOnInit(): Promise<void> {
    this.showLogin = !(await this.loginService.isLoggedUser());
  }
}
