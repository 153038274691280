// src/app/app.component.ts
import {Component, OnInit} from '@angular/core';
import {LoginComponent} from '../Auth/login/login.component';
import {LoginService} from '../../Services/Auth/login.service';
import {CommonModule} from '@angular/common';
import {Observable} from 'rxjs';
import {FavoritesComponent} from './articles/favorites/favorites.component';


@Component({
  selector: 'app-accueil',
  templateUrl: './accueil.component.html',
  styleUrls: ['./accueil.component.scss'],
  standalone: true,  // Déclaration comme composant standalone
  imports: [
    LoginComponent,
    CommonModule,
    FavoritesComponent
  ],  // Importation du composant modal standalone
})
export class AccueilComponent {

}
